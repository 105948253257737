<script lang="ts">
	import Modal from '$lib/components/modals/Modal.svelte';
	import {onMount} from 'svelte';
	import type {account as Account} from './';
	export let account: typeof Account;

	let doNotAskAgainSignature: boolean = false;
	let remoteSyncEnabled: boolean = true;

	onMount(() => {
		console.log(`on mount`);
		if (typeof $account.loadingStep?.data?.remoteSyncEnabled !== 'undefined') {
			console.log(
				`loading remoteSyncEnabled: ${$account.loadingStep?.data?.remoteSyncEnabled} from : ${remoteSyncEnabled}`,
			);
			remoteSyncEnabled = $account.loadingStep?.data?.remoteSyncEnabled;
		}
	});
</script>

<Modal>
	<h3 class="text-lg font-bold">Welcome to Stratagems</h3>
	<p class="py-4">
		In order to continue and get a safe place to save data, you'll need to sign a message. Be carefull and only sign
		this message on trusted frontend.
	</p>
	<div class="form-control">
		<label class="label cursor-pointer">
			<span class="label-text">Do not ask again (trust computer)</span>
			<input type="checkbox" bind:checked={doNotAskAgainSignature} class="checkbox" />
		</label>
		<label class="label cursor-pointer">
			<span class="label-text">Sync across devices (encrypted)</span>
			<input type="checkbox" bind:checked={remoteSyncEnabled} class="checkbox" />
		</label>
	</div>
	<div class="modal-action">
		<button on:click={() => account.rejectLoadingStep()} class="btn btn-error">Cancel</button>
		<button on:click={() => account.acceptLoadingStep({doNotAskAgainSignature, remoteSyncEnabled})} class="btn"
			>Sign</button
		>
	</div>
</Modal>
